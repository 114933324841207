import { ENV } from 'configs'
import { Storage } from 'models/storage'
import { Client, SubscriptionApp, User } from 'models/user'
import { CookieUtil } from 'utils/cookie'

export class CookieStorage<T> extends Storage<T> {
  get config(): { expires: string; path?: string; version: number } | null {
    if (this.type !== 'json') return null
    const value = CookieUtil.getJSON(this.key, this.sensitive) as any
    if (!value) return null
    return {
      expires: value.expires,
      path: value.path || '/',
      version: value.version,
    }
  }
  get value(): T | null {
    if (this.type === 'json') {
      return CookieUtil.getJSON(this.key, this.sensitive)
    } else {
      return CookieUtil.get(this.key, this.sensitive) as any
    }
  }

  constructor(
    key: string,
    type: 'json' | 'string',
    sensitive: boolean,
    version: number,
  ) {
    super(key, type, sensitive, version)
    this.checkOutdated()
  }

  set(value: T, expiresHours: number) {
    const expires = new Date(
      Date.now() + 3600 * 1000 * expiresHours,
    ).toUTCString()

    CookieUtil.set(
      this.key,
      { ...value, expires, version: this.version },
      expires,
      { encrypted: this.sensitive },
    )
  }

  clear() {
    CookieUtil.clear(this.key)
  }

  update(value: Partial<T>, expiresHours?: number) {
    if (!this.config) return
    let expires = this.config.expires
    if (expiresHours) {
      expires = new Date(Date.now() + 3600 * 1000 * expiresHours).toUTCString()
    }
    const mergedValues = {
      ...this.value,
      ...this.config,
      ...{ expires },
      ...value,
    }

    CookieUtil.set(this.key, mergedValues, expires, {
      encrypted: this.sensitive,
    })
  }

  checkOutdated() {
    if (this.config?.version !== this.version) {
      this.clear()
    }
  }
}

// curr version 4
export const authStorage = new CookieStorage<{
  user: User
  client: Client
  subscribedApps: SubscriptionApp[]
  refreshToken: string
}>(ENV.COOKIE_KEY, 'json', true, 4)

// curr version 2
export const tokenStorage = new CookieStorage<{
  accessToken: string
}>(`${ENV.COOKIE_KEY}-token`, 'json', true, 2)
