import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'assets/styles/index.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'

import store from 'store'
import { actions as appActions } from 'store/slices/app'
import { actions as authActions } from 'store/slices/auth'
import { commonDBHttpRequest, httpRequest } from 'services/http-request'
import { authStorage, tokenStorage } from 'services/cookie'
import { AuthAPI } from 'apis/auth'

dayjs.extend(relativeTime)

// window.test = { tokenStorage, httpRequest, dayjs, authStorage }

httpRequest.initRequest()
httpRequest.injectCallbacks({
  onCatchUnauthorizedError: () => {
    authStorage.clear()
    tokenStorage.clear()
    store.dispatch(authActions.clear())
    store.dispatch(authActions.expireSession())
  },
  onRetryUnauthorizedError: async () => {
    const refreshToken = authStorage.value?.refreshToken
    if (refreshToken) {
      await AuthAPI.refreshToken(refreshToken)
        .then((res) => {
          tokenStorage.update(
            {
              accessToken: res.data.access_token,
            },
            1,
          )
          httpRequest.setAuthorization(res.data.access_token, 'Bearer')
        })
        .catch((error) => {
          authStorage.clear()
          tokenStorage.clear()
          store.dispatch(authActions.clear())
        })
    }
  },
  onShowLoading: () => store.dispatch(appActions.showLoading()),
  onHideLoading: () => store.dispatch(appActions.hideLoading()),
  onSetAuthorization: () => {
    if (!tokenStorage.config?.expires) return
    const expireTime = dayjs(tokenStorage.config.expires).subtract(15, 'minute')
    const fromNow = expireTime.unix() - dayjs().unix() // s
    setTimeout(() => {
      const refreshToken = authStorage.value?.refreshToken
      if (refreshToken) {
        AuthAPI.refreshToken(refreshToken).then((res) => {
          tokenStorage.update(
            {
              accessToken: res.data.access_token,
            },
            1,
          )

          httpRequest.setAuthorization(res.data.access_token, 'Bearer')
        })
      }
    }, fromNow * 1000)
  },
})

commonDBHttpRequest.initRequest()
commonDBHttpRequest.injectCallbacks({
  onShowLoading: () => store.dispatch(appActions.showLoading()),
  onHideLoading: () => store.dispatch(appActions.hideLoading()),
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
