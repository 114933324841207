import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { actions as authActions } from 'store/slices/auth'
import { authStorage, tokenStorage } from 'services/cookie'
import { UserAPI } from 'apis/user'
import { RootState } from 'store/rootReducer'
import { httpRequest } from 'services/http-request'
import { AuthAPI } from 'apis/auth'
import { useToaster } from 'containers/Notification'

export const GlobalContainer: React.FC = (props) => {
  const dispatch = useDispatch()
  const toast = useToaster()
  const client = useSelector((state: RootState) => state.auth.client)
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  )
  const isExpired = useSelector((state: RootState) => state.auth.isExpired)

  const prevSubscriptionApps = useSelector(
    (state: RootState) => state.auth.subscribedApps,
  )

  const initAuth = async () => {
    try {
      // store auth value from storage
      const cachedAuth = authStorage.value
      if (!cachedAuth) throw new Error('Cached auth not found')
      let token = tokenStorage.value?.accessToken
      if (!token) {
        const res = await AuthAPI.refreshToken(cachedAuth.refreshToken)
        token = res.data.access_token

        tokenStorage.set(
          {
            accessToken: token,
          },
          1,
        )
      }

      if (!token) throw new Error('Token not found')
      httpRequest.setAuthorization(token, 'Bearer')
      dispatch(authActions.authen(cachedAuth))
    } catch (error) {
      console.error(error)
      dispatch(authActions.authWithFailed())
    }
  }

  useEffect(() => {
    if (client) {
      UserAPI.getSubscribedApp(client.id)
        .then((res) => {
          dispatch(authActions.setSubscribedApps(res.data.apps))
          if (!isEqual(res.data.apps, prevSubscriptionApps)) {
            authStorage.update({ subscribedApps: res.data.apps })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    if (!isAuthenticated) {
      httpRequest.deleteAuthorization()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isExpired) {
      toast.error('Your session has expired. Please sign in again!')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired])

  useEffect(() => {
    initAuth()
    // TODO: Get user info to avoid data outdated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <> {props.children} </>
}
